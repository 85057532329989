import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "./userService";
import { toast } from 'react-toastify';

// Async Thunk for Registering User
export const registerUser = createAsyncThunk("auth/register", async (userData, thunkAPI) => {
  try {
    const response = await authService.register(userData);
    return response;
  } catch (error) {
    // Return a more informative error message if available
    const errorMessage = error.response?.data?.message || error.message;
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

// Async Thunk for Registering User
export const loginUser = createAsyncThunk("auth/login", async (userData, thunkAPI) => {
  try {
    const response = await authService.login(userData);
    return response;
  } catch (error) {
    // Return a more informative error message if available
    const errorMessage = error.response?.data?.message || error.message;
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

// export const getUSerProductWishlist = createAsyncThunk(
//   "user/wishlist",
//   async (thunkAPI) => {
//     try{
//       return await authService.getUserWishlist();
//     }catch (error){
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const   getUSerProductWishlist = createAsyncThunk(
  "user/wishlist",
  async (thunkAPI) => {
    try{
      return await authService.getUserWishlist();
    }catch (error){
      return thunkAPI.rejectWithValue(error);
    }
  } )

const getCustomerfromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

// Initial State
const initialState = {
  user: getCustomerfromLocalStorage,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload; // Changed from `createdUser` to `user`
        if (state.isSuccess) {
          toast.info("User Created Successfully");
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload; // Get detailed error message
        if (state.isError) {
          toast.error(state.message); // Fixed toast message
        }
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.user = action.payload; // Changed from `createdUser` to `user`
        if (state.isSuccess) {
            localStorage.setItem("token", action.payload.token)
            toast.info("User Logged In Successfully");
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload; // Get detailed error message
        if (state.isError) {
          toast.error(state.message); // Fixed toast message
        }
      })

      .addCase(getUSerProductWishlist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUSerProductWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.wishlist = action.payload;
      })
      .addCase(getUSerProductWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message= action.error;
      });
  },
});

export default authSlice.reducer;
