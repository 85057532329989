import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "./productService";

// Thunk to fetch all products
export const getAllProducts = createAsyncThunk("product/get", async (query = "", thunkAPI) => {
  try {
    const response = await productService.getProducts(query);
    console.log("Fetched Products:", response); // Debugging log
    return response;
  } catch (error) {
    console.error("API Error:", error); // Debugging log
    return thunkAPI.rejectWithValue(error.response?.data || "Error fetching products");
  }
});

// Thunk to fetch single product details
export const getProductDetails = createAsyncThunk(
  "product/getProductDetails",
  async (prodId, thunkAPI) => {
    try {
      const response = await productService.getProductDetails(prodId);
      console.log("Product Details:", response); // Debugging log
      return response;
    } catch (error) {
      console.error("Error fetching product details:", error); // Debugging log
      return thunkAPI.rejectWithValue(error.response?.data || "Error fetching product details");
    }
  }
);

// Thunk to add a product to the wishlist
export const addToWishlist = createAsyncThunk(
  "product/addToWishlist",
  async (prodId, thunkAPI) => {
    try {
      const response = await productService.addToWishlist(prodId);
      console.log("Add to Wishlist Response:", response); // Debugging log
      return response;
    } catch (error) {
      console.error("Error adding product to wishlist:", error); // Debugging log
      return thunkAPI.rejectWithValue(error.response?.data || "Error adding to wishlist");
    }
  }
);


// Thunk to fetch products by category ID
export const getProductsByCategory = createAsyncThunk(
  "product/getProductsByCategory",
  async (categoryId, thunkAPI) => {
    try {
      const response = await productService.getProductsByCategory(categoryId);
      console.log("Fetched Products by Category:", response); // Debugging log
      return response;
    } catch (error) {
      console.error("Error fetching products by category:", error); // Debugging log
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error fetching products by category"
      );
    }
  }
);
export const getProductsByCategoryWithSubcategories = createAsyncThunk(
  "product/getProductsByCategoryWithSubcategories",
  async (categoryId, thunkAPI) => {
    try {
      const response = await productService.getProductsByCategoryWithSubcategories(categoryId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Error fetching products by category");
    }
  }
);


// Initial state
const productState = {
  products: [], // List of products
  productDetails: {}, // Single product details
  productsByCategory: [], // List of products by category
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Slice
export const productSlice = createSlice({
  name: "product",
  initialState: productState,
  reducers: {
    resetProductState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all products
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Fetch product details
      .addCase(getProductDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.productDetails = action.payload;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getProductsByCategoryWithSubcategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsByCategoryWithSubcategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.productsByCategory = action.payload;
      })
      .addCase(getProductsByCategoryWithSubcategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Add to wishlist
      .addCase(addToWishlist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Product added to wishlist!";
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetProductState } = productSlice.actions;
export default productSlice.reducer;
