import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { AiOutlineClose } from "react-icons/ai";
import { BsChatDotsFill, BsWhatsapp } from "react-icons/bs";

const socket = io("http://localhost:5000"); // Replace with your backend URL

const Chat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const whatsappNumber = "+923105710500"; // Replace with your WhatsApp number

  useEffect(() => {
    // Listen for incoming messages
    socket.on("receiveMessage", (data) => {
      setChatHistory((prev) => [...prev, data]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const toggleChat = () => {
    setIsOpen((prev) => !prev);
  };

  const sendMessage = () => {
    if (message.trim()) {
      const data = { sender: "User", message };
      socket.emit("sendMessage", data);
      setChatHistory((prev) => [...prev, data]);
      setMessage("");
    }
  };

  const redirectToWhatsApp = () => {
    const whatsappURL = `https://wa.me/${whatsappNumber.replace(
      /\D/g,
      ""
    )}?text=Hi, I need support regarding your services.`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Chat Toggle Button */}
      {!isOpen && (
        <button
          onClick={toggleChat}
          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-4 rounded-full shadow-xl hover:scale-105 transition-transform"
        >
          <BsChatDotsFill size={24} />
        </button>
      )}

      {/* Chat Box */}
      {isOpen && (
        <div className="w-full max-w-sm md:max-w-md lg:max-w-lg bg-white rounded-lg shadow-2xl">
          {/* Header */}
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-3 flex justify-between items-center rounded-t-lg">
            <h3 className="text-sm md:text-base font-semibold">
              Chat with Support
            </h3>
            <button
              onClick={toggleChat}
              className="text-white text-xl hover:scale-110 transition-transform"
            >
              <AiOutlineClose />
            </button>
          </div>

          {/* WhatsApp Section */}
          <div className="p-3 border-b bg-gray-50 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <BsWhatsapp className="text-green-500" size={20} />
              <span className="text-xs md:text-sm text-gray-700 font-medium">
                Need immediate help?
              </span>
            </div>
            <button
              onClick={redirectToWhatsApp}
              className="bg-green-500 text-white px-3 py-1 text-xs md:text-sm rounded-full font-medium hover:bg-green-600 transition-all"
            >
              Contact Us
            </button>
          </div>

          {/* Chat History */}
          <div className="p-3 h-40 md:h-48 lg:h-56 overflow-y-auto bg-gray-50">
            {chatHistory.length > 0 ? (
              chatHistory.map((chat, index) => (
                <div
                  key={index}
                  className={`mb-2 ${
                    chat.sender === "User" ? "text-right" : "text-left"
                  }`}
                >
                  <p
                    className={`${
                      chat.sender === "User"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    } inline-block rounded-lg p-2 shadow-sm text-xs md:text-sm`}
                  >
                    {chat.message}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    {chat.sender === "User" ? "You" : "Support"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500 text-xs md:text-sm">
                No messages yet.
              </p>
            )}
          </div>

          {/* Input Section */}
          <div className="p-3 border-t bg-gray-100 flex items-center">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-grow p-2 md:p-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all text-xs md:text-sm"
            />
            <button
              onClick={sendMessage}
              className="ml-2 bg-blue-500 text-white px-3 py-2 rounded-full shadow-md hover:bg-blue-600 transition-all text-xs md:text-sm"
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
