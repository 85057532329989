import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import { Link, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaHome,
  FaEnvelope,
  FaBriefcase,
  FaChevronDown,
  FaUserFriends,
} from "react-icons/fa";
import { X } from "lucide-react";

const Sidebar = ({
  isSidebarOpen,
  closeAllOverlays,
  backdropVariants,
  sidebarVariants,
  colors,
  categories,
  toggleProductsDropdown,
  isProductsDropdownOpen,
}) => {
  return (
    <>
      <Helmet>
        <title>Sidebar - Spartans Wears</title>
        <meta name="description" content="Explore categories and navigate through Spartans Wears with ease." />
        <link rel="preload" href="/images/SPARTANS.webp" as="image" />
        {categories.map((category) => (
          <link
            key={category._id}
            rel="preload"
            href={`/product?subcategory=${category.title}`}
            as="fetch"
          />
        ))}
      </Helmet>

      <AnimatePresence>
        {isSidebarOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              className="fixed inset-0 bg-black/60 z-40"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={backdropVariants}
              onClick={closeAllOverlays}
            />

            {/* Sidebar */}
            <motion.div
              className="fixed left-0 top-0 bottom-0 w-full sm:w-80 md:w-96 bg-white z-50 font-poppins flex flex-col"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={sidebarVariants}
            >
              {/* Header */}
              <div className={`relative p-6 border-b ${colors.border} bg-white`}>
                {/* Close Button */}
                <button
                  onClick={closeAllOverlays}
                  className={`absolute top-4 right-4 p-4 rounded-full group transition-all duration-300 ease-in-out ${colors.hover}`}
                  style={{ zIndex: 50 }}
                >
                  <X
                    className={`w-6 h-6 ${colors.secondary} group-hover:${colors.primary} transition-colors`}
                  />
                </button>

                {/* Logo */}
                <Link
                  to="/"
                  className="flex justify-center mb-6 transform transition-transform duration-300 hover:scale-105"
                >
                  <img
                    src="/images/SPARTANS.webp"
                    alt="Spartans Logo"
                    className="h-20 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-500 ease-in-out"
                  />
                </Link>
              </div>

              {/* Navigation */}
              <div className="flex-grow overflow-y-auto py-4 px-6 space-y-4 bg-gray-50 custom-scrollbar">
                <nav className="space-y-2">
                  {/* Static Links */}
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                        isActive
                          ? "bg-blue-100 text-blue-600 font-semibold"
                          : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                      }`
                    }
                    onClick={closeAllOverlays}
                  >
                    <FaHome className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                    <span>Home</span>
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                        isActive
                          ? "bg-blue-100 text-blue-600 font-semibold"
                          : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                      }`
                    }
                    onClick={closeAllOverlays}
                  >
                    <FaEnvelope className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                    <span>Contact Us</span>
                  </NavLink>

                  {/* Products Dropdown */}
                  <div className="relative">
                    <button
                      className={`w-full flex justify-between items-center py-3 px-4 rounded-lg ${colors.secondary} ${colors.hover} group transition-all duration-300 ease-in-out`}
                      onClick={toggleProductsDropdown}
                    >
                      <div className="flex items-center">
                        <FaBriefcase className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                        <span className="font-medium group-hover:text-blue-600">
                          Products
                        </span>
                      </div>
                      <FaChevronDown
                        className={`w-5 h-5 transition-transform duration-300 ${
                          isProductsDropdownOpen
                            ? "rotate-180 text-blue-600"
                            : ""
                        }`}
                      />
                    </button>

                    {isProductsDropdownOpen && (
                      <div className="mt-2 space-y-2 pl-4 pr-4 bg-white rounded-lg shadow-md py-2">
                        {categories.map((category) => (
                          <div key={category._id} className="space-y-1">
                            {/* Category */}
                            <div className="text-gray-700 font-semibold mb-1 px-3">
                              {category.title}
                            </div>
                            {/* Subcategories */}
                            {category.subcategories.map((subcategory) => (
                              <NavLink
                                key={subcategory._id}
                                to={`/product?subcategory=${subcategory.title}`}
                                className={({ isActive }) => ` 
                                  block py-2 px-3 rounded-md text-sm 
                                  transition-all duration-300 ease-in-out
                                  ${
                                    isActive
                                      ? "bg-blue-100 text-blue-600 font-medium"
                                      : "text-gray-500 hover:bg-blue-50 hover:text-blue-600 hover:translate-x-1"
                                  }
                                `}
                                onClick={closeAllOverlays}
                              >
                                {subcategory.title}
                              </NavLink>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                        isActive
                          ? "bg-blue-100 text-blue-600 font-semibold"
                          : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                      }`
                    }
                    onClick={closeAllOverlays}
                  >
                    <FaUserFriends className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                    <span>About Us</span>
                  </NavLink>
                </nav>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
