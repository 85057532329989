// src/features/pcategory/pcategoryService.js

import axios from "axios";
import { base_url, config } from "../../utils/axiosConfig";

// Fetch all categories
const getCategories = async () => {
  try {
    const response = await axios.get(`${base_url}category`);
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

// Fetch subcategories by category ID
const getSubcategories = async (categoryId) => {
  try {
    const response = await axios.get(`${base_url}category/subcategory/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching subcategories:", error);
    throw error;
  }
};

// Create a new category
const createCategory = async (categoryData) => {
  try {
    const response = await axios.post(`${base_url}category`, categoryData, config);
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

// Create a new subcategory
const createSubcategory = async (subcategoryData) => {
  try {
    const response = await axios.post(`${base_url}category/subcategory`, subcategoryData, config);
    return response.data;
  } catch (error) {
    console.error("Error creating subcategory:", error);
    throw error;
  }
};

// Service exports
const pcategoryService = {
  getCategories,
  getSubcategories,
  createCategory,
  createSubcategory,
};

export default pcategoryService;
