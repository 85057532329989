import React, { useState, useEffect, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Chat from "./components/Chat"; // Import Chat component
import Preloader from "./components/PreLoader"; // Import Preloader

// Lazy Loading Pages
const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const OurStore = React.lazy(() => import("./pages/OurStore"));
const CompareProduct = React.lazy(() => import("./pages/CompareProduct"));
const Wishlist = React.lazy(() => import("./pages/Wishlist"));
const Login = React.lazy(() => import("./pages/Login"));
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Resetpassword = React.lazy(() => import("./pages/Resetpassword"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = React.lazy(() => import("./pages/RefundPolicy"));
const ShippingPolicy = React.lazy(() => import("./pages/ShippingPolicy"));
const TermAndConditions = React.lazy(() => import("./pages/TermAndConditions"));
const SingleProduct = React.lazy(() => import("./pages/SingleProduct"));
const Cart = React.lazy(() => import("./pages/Cart"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simulate loading time
    }, 1500); // Adjust duration as needed
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Preloader />; // Show Preloader while loading
  }

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="product" element={<OurStore />} />
              <Route path="product/:id" element={<SingleProduct />} />
              <Route path="cart" element={<Cart />} />
              <Route path="compare-product" element={<CompareProduct />} />
              <Route path="wishlist" element={<Wishlist />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<Forgotpassword />} />
              <Route path="signup" element={<Signup />} />
              <Route path="reset-password" element={<Resetpassword />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="refund-policy" element={<RefundPolicy />} />
              <Route path="shipping-policy" element={<ShippingPolicy />} />
              <Route path="term-conditions" element={<TermAndConditions />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>

      {/* Real-Time Chat Component */}
      <Chat />
    </>
  );
}

export default App;
