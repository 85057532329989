import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  BsLinkedin, 
  BsGithub, 
  BsYoutube, 
  BsInstagram, 
  BsEnvelope, 
  BsSend 
} from 'react-icons/bs';
import { RiMailSendFill } from "react-icons/ri";
// import newsletter from "images/newsletter.png";

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    // Implement newsletter subscription logic
    if (email) {
      console.log('Subscribing:', email);
      // Add actual subscription mechanism
      setEmail('');
    }
  };

  const socialLinks = [
    { Icon: BsLinkedin, href: '#' },
    { Icon: BsInstagram, href: '#' },
    { Icon: BsGithub, href: '#' },
    { Icon: BsYoutube, href: '#' }
  ];

  const quickLinks = [
    { title: 'Privacy Policy', to: '/privacy-policy' },
    { title: 'Refund Policy', to: '/refund-policy' },
    { title: 'Shipping Policy', to: '/shipping-policy' },
    { title: 'Terms & Conditions', to: '/term-conditions' }
  ];

  const accountLinks = [
    { title: 'About Us', to: '/about-us' },
    { title: 'FAQ', to: '/faq' },
    { title: 'Contact', to: '/contact' }
  ];

  const productLinks = [
    { title: 'Leather Jackets', to: '/leather-jackets' },
    { title: 'Bike Suits', to: '/bike-suits' },
    { title: 'Sports Wear', to: '/sports-wear' },
    { title: 'Fitness Wear', to: '/fitness-wear' }
  ];

  return (
    <footer className="bg-gradient-to-br from-gray-900 to-black text-white">
      {/* Newsletter Section */}
      <div className="container mx-auto px-4 py-8 border-b border-gray-700">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4">
            <RiMailSendFill 
              // src={newsletter} 
              alt="newsletter" 
              className="w-16 h-16 object-contain"
            />
            <h2 className="text-2xl font-bold tracking-tight">
              Get Up to date
            </h2>
          </div>
          
          <div className="flex flex-col md:flex-row w-full md:w-1/2 space-y-2 md:space-y-0 md:space-x-2">
            <div className="relative flex-grow">
              <input 
                type="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address" 
                className="w-full px-4 py-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
              <BsEnvelope className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <button 
              onClick={handleSubscribe}
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg flex items-center space-x-2 transition duration-300 group"
            >
              <span>Subscribe</span>
              <BsSend className="ml-2 group-hover:translate-x-1 transition" />
            </button>
          </div>
        </div>
      </div>

      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Contact Section */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
            <address className="not-italic text-gray-300 space-y-2">
              <p>Hno: 29, Yaqoob Street</p>
              <p>Model Town, Sialkot</p>
              <p>Postal Code: 51310</p>
            </address>
            <div className="mt-4 space-y-2">
              <a 
                href="tel:+913105710500" 
                className="block hover:text-blue-400 transition"
              >
                +91 3105710500
              </a>
              <a 
                href="mailto:adnankhawar005@gmail.com" 
                className="block hover:text-blue-400 transition"
              >
                adnankhawar005@gmail.com
              </a>
            </div>
            
            {/* Social Icons */}
            <div className="flex space-x-4 mt-6">
              {socialLinks.map(({ Icon, href }, index) => (
                <a 
                  key={index} 
                  href={href} 
                  className="text-gray-300 hover:text-white transition transform hover:scale-110"
                >
                  <Icon size={24} />
                </a>
              ))}
            </div>
          </div>

          {/* Footer Link Columns */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Information</h4>
            {quickLinks.map(({ title, to }, index) => (
              <Link 
                key={index} 
                to={to} 
                className="block text-gray-300 hover:text-white hover:translate-x-2 transition mb-2"
              >
                {title}
              </Link>
            ))}
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-4">Account</h4>
            {accountLinks.map(({ title, to }, index) => (
              <Link 
                key={index} 
                to={to} 
                className="block text-gray-300 hover:text-white hover:translate-x-2 transition mb-2"
              >
                {title}
              </Link>
            ))}
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            {productLinks.map(({ title, to }, index) => (
              <Link 
                key={index} 
                to={to} 
                className="block text-gray-300 hover:text-white hover:translate-x-2 transition mb-2"
              >
                {title}
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="bg-black/20 py-4 text-center">
        <p className="text-gray-400">
          &copy; {new Date().getFullYear()} Powered by Motive Solutions. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;