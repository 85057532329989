// src/app/store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/user/userSlice";
import productReducer from "../features/products/productSlice";
import pcategoryReducer from "../features/pcategory/pcategorySlice"; // Import the pcategory reducer

export const store = configureStore({
  reducer: {
    auth: authReducer, // User authentication and management
    product: productReducer, // Product-related state
    pcategory: pcategoryReducer, // Category and subcategory management
  },
});

export default store;
