// src/components/HeaderTop.js
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Phone, Mail } from "lucide-react";

const HeaderTop = () => {
  return (
    <div className="bg-gradient-to-br from-gray-900 to-black text-white text-sm py-2 border-b border-zinc-800 font-poppins">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Text Content */}
          <p className="text-center md:text-left mb-2 md:mb-0 text-xs sm:text-sm">
            Welcome you to SPARTANS WEARS
          </p>

          {/* Links and Icons */}
          <div className="flex items-center space-x-4 sm:space-x-6">
            <div className="hidden md:flex items-center space-x-2 sm:space-x-4">
              {/* Social Media Icons */}
              <Link
                to="#"
                className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
              >
                <FaFacebook className="w-4 h-4 sm:w-5 sm:h-5" />
              </Link>
              <Link
                to="#"
                className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
              >
                <FaTwitter className="w-4 h-4 sm:w-5 sm:h-5" />
              </Link>
              <Link
                to="#"
                className="hover:text-pink-400 transition-transform duration-200 transform hover:scale-110"
              >
                <FaInstagram className="w-4 h-4 sm:w-5 sm:h-5" />
              </Link>
              <Link
                to="#"
                className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
              >
                <FaLinkedin className="w-4 h-4 sm:w-5 sm:h-5" />
              </Link>

              {/* Separator */}
              <span className="text-zinc-600 hidden lg:block">|</span>

              {/* Phone Link */}
              <Link
                to="tel:+92 3105710500"
                className="flex items-center hover:text-gray-300 transition-colors duration-200 text-xs sm:text-sm"
              >
                <Phone className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
                0310-5710500
              </Link>

              {/* Separator */}
              <span className="text-zinc-600 hidden lg:block">|</span>

              {/* Email Link */}
              <Link
                to="mailto:info@spartanswears.com"
                className="flex items-center hover:text-gray-300 transition-colors duration-200 text-xs sm:text-sm"
              >
                <Mail className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
                info@spartanswears.com
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
