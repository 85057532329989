import axios from "axios";
import { base_url, config } from "../../utils/axiosConfig";

const register = async (userData) => {
  const response = await axios.post(`${base_url}user/register`, userData);
  if(response.data) {
    if (response.data) {
    localStorage.setItem("customer", JSON.stringify(response.data));
  }
    return response.data;
  }
};
const login = async (userData) => {
  const response = await axios.post(`${base_url}user/login`, userData);
  return response.data; // Ensure consistent return
};

const getUserWishlist = async () => {
  const response = await axios.get(`${base_url}user/wishlist`, config);
  if (response.data){
    return response.data; 
  }
} 

export const authService = {
  register,
  login,
  getUserWishlist,
};
