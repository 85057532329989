import axios from "axios";
import { base_url, config } from "../../utils/axiosConfig";

// Fetch all products with optional query params
const getProducts = async (query = "") => {
  try {
    const response = await axios.get(`${base_url}product${query}`);
    console.log("Products API Response:", response.data); // Debugging log
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error); // Debugging log
    throw error;
  }
};

// Add a product to the wishlist
const addToWishlist = async (prodId) => {
  try {
    const response = await axios.put(`${base_url}product/wishlist`, { prodId }, config);
    console.log("Wishlist Response:", response.data); // Debugging log
    return response.data;
  } catch (error) {
    console.error("Error adding to wishlist:", error);
    throw error;
  }
};

// Fetch product details by ID
const getProductDetails = async (prodId) => {
  try {
    const response = await axios.get(`${base_url}product/${prodId}`, config);
    console.log("Product Details Response:", response.data); // Debugging log
    return response.data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    throw error;
  }
};

const getProductsByCategory = async (categoryId) => {
  try {
    const response = await axios.get(`${base_url}product/products/by-category/${categoryId}`); // Confirm this matches backend route
    return response.data;
  } catch (error) {
    console.error("Error fetching products by category:", error);
    throw error;
  }
};

const getProductsByCategoryWithSubcategories = async (categoryId) => {
  try {
    const response = await axios.get(`${base_url}products/by-category-with-subcategories/${categoryId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// Exporting product service functions
export const productService = {
  getProducts,
  addToWishlist,
  getProductDetails,
  getProductsByCategory,
  getProductsByCategoryWithSubcategories,
};
