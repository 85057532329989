// src/components/Preloader.js
import React from "react";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="gradient-overlay"></div>
      <div className="spinner">
        <div className="circle"></div>
      </div>
      <style jsx>{`
        .preloader {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("images/preloader.webp") center center no-repeat;
          background-size: cover; /* Ensure the background image covers the screen */
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          overflow: hidden;
        }

        .gradient-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            135deg,
            rgba(52, 73, 94, 0.8),
            rgba(44, 62, 80, 0.8)
          );
          z-index: 1;
        }

        .spinner {
          position: relative;
          width: 100px;
          height: 100px;
          z-index: 2;
        }

        .circle {
          width: 100%;
          height: 100%;
          border: 6px solid transparent;
          border-top: 6px solid #3498db; /* Outer circle primary color */
          border-right: 6px solid #8e44ad; /* Outer circle secondary color */
          border-radius: 50%;
          animation: spin 1.5s linear infinite;
        }

        /* Spinner animation */
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        /* Inner pulsing glow effect */
        .circle::before {
          content: "";
          position: absolute;
          top: 15px;
          left: 15px;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: radial-gradient(
            circle,
            rgba(52, 152, 219, 0.4),
            rgba(142, 68, 173, 0.2)
          );
          animation: pulse 2s infinite ease-in-out;
        }

        /* Glow effect animation */
        @keyframes pulse {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          50% {
            transform: scale(1.2);
            opacity: 0.7;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default Preloader;
