// src/components/MainNavbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";

const MainNavbar = ({ isHomePage, categories, location }) => {
  const [isProductssDropdownOpen, setIsProductssDropdownOpen] = useState(false);

  return (
    <nav
      className={`hidden md:flex items-center justify-between space-x-8 px-6 py-4 text-sm font-poppins ${
        isHomePage ? "text-white" : "text-black"
      }`}
    >
      <div className="flex items-center space-x-1">
        {/* Home */}
        <div className="relative group">
          <Link
            to="/"
            className={`py-2 px-4 relative transition-all duration-300 ${
              isHomePage
                ? "text-whitesmoke font-semibold"
                : location.pathname === "/"
                ? "text-black font-semibold"
                : "text-black"
            }`}
          >
            Home
            <span
              className={`absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left`}
            ></span>
          </Link>
        </div>

        {/* About Us */}
        <div className="relative group">
          <Link
            to="/about"
            className={`py-2 px-4 relative transition-all duration-300 ${
              isHomePage ? "text-whitesmoke" : "text-black"
            }`}
          >
            About Us
            <span className="absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
          </Link>
        </div>

        {/* Products Dropdown */}
        <div
          className="relative group"
          onMouseEnter={() => setIsProductssDropdownOpen(true)}
          onMouseLeave={() => setIsProductssDropdownOpen(false)}
        >
          <button
            className={`py-2 px-4 flex items-center relative transition-all duration-300 ${
              isHomePage
                ? "text-whitesmoke hover:text-blue-300"
                : "text-black hover:text-blue-500"
            } group`}
          >
            Products
            {isProductssDropdownOpen ? (
              <ChevronUp className="ml-1 w-4 h-4" />
            ) : (
              <ChevronDown className="ml-1 w-4 h-4" />
            )}
            <span
              className={`absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 ${
                isHomePage ? "bg-whitesmoke" : "bg-black"
              } group-hover:scale-x-100 group-hover:bg-blue-500 transition-transform duration-300 origin-left`}
            ></span>
          </button>

          {/* Dropdown */}
          {isProductssDropdownOpen && (
            <div
              className={`fixed ${
                isHomePage ? "top-[115px]" : "top-[105px]"
              } left-0 w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 shadow-2xl z-50`}
            >
              <div className="container mx-auto px-10 py-10 flex items-start justify-between gap-10">
                {/* Categories Section */}
                <div className="flex-grow grid grid-cols-3 gap-x-24 gap-y-10">
                  {categories.map((category) => (
                    <div
                      key={category._id}
                      className="space-y-6 text-center flex flex-col items-center animate-fadeIn"
                    >
                      {/* Category Header */}
                      <h4 className="text-2xl font-black text-gray-100 border-b-2 border-gray-700 pb-3 tracking-wider uppercase">
                        {category.title}
                      </h4>
                      {/* Subcategories */}
                      <div className="space-y-3">
                        {category.subcategories.map((subcategory) => (
                          <Link
                            key={subcategory._id}
                            to={`/product?subcategory=${subcategory.title}`}
                            className="block text-[16px] text-gray-300 hover:text-blue-400 hover:translate-x-2 transition-all duration-300"
                            onClick={() =>
                              setIsProductssDropdownOpen(false)
                            } // Close dropdown on click
                          >
                            {subcategory.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Featured Image Section */}
                <div className="flex-shrink-0 w-1/4 flex flex-col items-center justify-center p-8 bg-gradient-to-br from-gray-800 via-gray-700 to-gray-800 rounded-xl shadow-lg">
                  {/* Image */}
                  <img
                    src="/images/SPARTANS.webp"
                    alt="Category Preview"
                    className="w-full max-h-[300px] rounded-lg shadow-xl transition-transform hover:scale-110 duration-500 object-cover"
                  />
                  {/* Caption */}
                  <div className="text-center mt-6">
                    <p className="text-sm text-gray-400 italic tracking-wide">
                      Featured Category
                    </p>
                    <h3 className="text-xl font-extrabold text-white uppercase tracking-wider">
                      Spartans Collection
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Contact Us */}
        <div className="relative group">
          <Link
            to="/contact"
            className={`py-2 px-4 relative transition-all duration-300 ${
              isHomePage ? "text-whitesmoke" : "text-black"
            }`}
          >
            Contact Us
            <span className="absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default MainNavbar;
