// src/features/pcategory/pcategorySlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import pcategoryService from "./pcategoryService";

// Thunk to fetch categories
export const getCategories = createAsyncThunk("pcategory/getCategories", async (_, thunkAPI) => {
  try {
    return await pcategoryService.getCategories();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || "Error fetching categories");
  }
});

// Thunk to fetch subcategories by category ID
export const getSubcategories = createAsyncThunk("pcategory/getSubcategories", async (categoryId, thunkAPI) => {
  try {
    return await pcategoryService.getSubcategories(categoryId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || "Error fetching subcategories");
  }
});

// Initial state for the slice
const initialState = {
  pCategories: [], // All product categories
  subcategories: [], // Subcategories for a selected category
  isLoading: false, // Loading status
  isError: false, // Error status
  isSuccess: false, // Success status
  message: "", // Error or success messages
};

// Slice definition
const pcategorySlice = createSlice({
  name: "pcategory",
  initialState,
  reducers: {
    // Reset state reducer
    resetState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get categories
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.pCategories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      // Get subcategories
      .addCase(getSubcategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subcategories = action.payload;
      })
      .addCase(getSubcategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

// Exporting actions and reducer
export const { resetState } = pcategorySlice.actions;
export default pcategorySlice.reducer;
